import React, { Component } from 'react';
import { withMixpanel } from 'gatsby-plugin-mixpanel';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../components/images/HeroImage';
import PersonalizeImage from '../components/images/PersonalizeImage';
import RotateImage from '../components/images/RotateImage';
import RepeatImage from '../components/images/RepeatImage';

class Index extends Component {
    render() {
      return (
        <Layout>
          <SEO title="Fine art for the connoisseur on a budget" />
          <section className="pt-20 md:pt-40">
            <div className="container mx-auto px-8 lg:flex">
              <div className="lg:text-left lg:w-1/2">
                <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                  Fine art
                </h1>
                <h5 className="text-xl lg:text-2xl leading-none" style={{ maxWidth: '100%', textAlign: 'justify' }}>
                  for the connoisseur on a budget
                </h5>
                <p className="text-xl lg:text-2xl mt-6 font-light">
                  Make every room a sanctuary to replenish your spirit and enrich your soul
                </p>
                <p className="mt-8 md:mt-12" onClick={() => {
                  this.props.mixpanel.track('Get started');
                  window.location.href='https://gallery.valencarte.com/'
                }}>
                  <Button size="lg">View gallery</Button>
                </p>
              </div>
              <div className="lg:w-1/2 mt-6">
                <HeroImage />
              </div>
            </div>
          </section>
          <section className="container mx-auto my-20 py-24 rounded-lg">
          <div id="services"></div>
            <h3 className="text-5xl font-semibold text-center">Luxury. Redefined.</h3>
              <SplitSection
                primarySlot={
                  <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">
                      Personalize your curation
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Immerse yourself in a collection that mirrors the essence of your journey.
                    </p>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Generate new pieces that resonate with your inner world, letting them whisper the truths you hold dear.
                    </p>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      This is where your space begins to align with the beauty of your soul.
                    </p>
                  </div>
                }
                secondarySlot={<PersonalizeImage />}
              />
              <SplitSection
                reverseOrder
                primarySlot={
                  <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">
                      Infuse your collection
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Embrace the fluidity of life by allowing your surroundings to evolve with you.
                    </p>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Let each change reflect a new chapter in your story, where art becomes a living testament to your growth, your experiences, and the boundless potential within you.
                    </p>
                  </div>
                }
                secondarySlot={<RotateImage />}
              />
              <SplitSection
                primarySlot={
                  <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">
                      Repeat to refine
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Life is a continuous journey of becoming.
                    </p>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Revisit, reflect, and refine your choices, letting each iteration bring you closer to a space that not only surrounds you with beauty but also nourishes your soul, guiding you toward a deeper understanding of yourself.
                    </p>
                  </div>
                }
                secondarySlot={<RepeatImage />}
              />
          </section>
          <section id="testimonials" className="py-20 lg:py-40">
            <div className="container mx-auto">
              <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
              <div className="flex flex-col md:flex-row md:-mx-3">
                {customerData.map(customer => (
                  <div key={customer.customerName} className="flex-1 px-3">
                    <CustomerCard customer={customer} />
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section id='start-today' className="container mx-auto my-20 py-24 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">Luxury. Achieved.</h3>
            <p className="mt-8 md:mt-12" onClick={() => {
              this.props.mixpanel.track('Start today')
              window.location.href='https://gallery.valencarte.com/'
            }}>
              <Button size="lg">Browse now</Button>
            </p>
          </section>
        </Layout>
      );
    }
};

export default withMixpanel()(Index);
