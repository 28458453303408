export default [
  {
    title: 'Enriching and Uplifting',
    content:
      'Each piece feels deeply personal, turning my home into a haven where I find peace and inspiration.',
    customerName: 'Avery',
    customerTitle: 'San Francisco, CA'
  },
  {
    title: 'A Dynamic Reflection of Life',
    content:
      'Seriously love opening a new piece every month to infuse my space with new energy!',
    customerName: 'Jordan',
    customerTitle: 'New York, NY'
  },
  {
    title: 'Thoughtful and Deeply Personal',
    content:
      'Perfect for anyone seeking a meaningful, evolving expression of their inner world at home.',
    customerName: 'Morgan',
    customerTitle: 'Chicago, IL',
  }
];
